/* eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import store from './store';

Vue.use(VueRouter);

const routes = [
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "Login" */ './components/Login.vue')
    }, {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "Login" */ './components/Dashboard.vue')
    }, {
        path: '/company-list',
        name: 'companyList',
        component: () => import(/* webpackChunkName: "Login" */ './components/companyList.vue')
    }, {
        path: '/company-details',
        name: 'companyDetails',
        component: () => import(/* webpackChunkName: "Login" */ './components/companyDetails.vue')
    },
    {
      path: '/',
      meta: {
        requiresAuth: true
      },
    },
  ];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    }
  });

router.beforeEach((to, from, next) => {
    if (to.name === 'Adhoc' || to.name === 'NewAdhoc' || to.name === 'ViewAdhoc') {
      store.state.isAdhocView = true;
      store.state.hideAdhoc = false;
    } else if (to.name === 'EditAdhoc') {
      store.state.hideAdhoc = true;
    } else {
      store.state.hideAdhoc = false;
      store.state.isAdhocView = false;
    }
    next();
  });
  
  router.beforeEach((to, from, next) => {
    const routePath = to.path.split('/');
    const currentPath = routePath[1];
    store.state.activeNavigation = currentPath;
    if (store.state.user && store.state.user.access_token) {
      store.dispatch('inspectToken');
    }
    let authInterceptor;
    if (to.matched.some(record => record.meta.requiresAuth) && store.state.user) {
      if (store.state.user && store.state.user.access_token) {
        store.dispatch('inspectToken');
      }
      authInterceptor = axios.interceptors.request.use((config) => {
        const configTemp = config;
        configTemp.headers.Authorization = `JWT ${store.state.user.access_token}`;
        configTemp.headers['X-CSRFToken'] = store.state.user.csrf_token;
        configTemp.headers['dashboard-version'] = 'v1';
        return configTemp;
      }, error => Promise.reject(error));
    } else if (to.matched.some(record => record.meta.requiresAuth) && !store.state.user) {
      axios.interceptors.request.eject(authInterceptor);
      console.log(to, 'hthth');
      if (to.hash) {
        next({
          path: '/login',
          query: {
            redirect: to.hash
          }
        });
      } else {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        });
      }
    } else {
      axios.interceptors.request.eject(authInterceptor);
      if (to.hash) {
        next({
          path: '/login',
          query: {
            redirect: to.hash
          }
        });
      } else {
        next();
      }
    }
  
    if (!store.state.user) {
      axios.interceptors.request.use((config) => {
        const configTemp = Object.assign({}, config);
        delete configTemp.headers.Authorization;
        if (store && store.state && store.state.user && store.state.user.access_token) {
          configTemp.headers.Authorization = `JWT ${store.state.user.access_token}`;
        }
        return configTemp;
      }, error => Promise.reject(error));
    }
    axios.interceptors.response.use(response => response, (error) => {
      let rtn = false;
      if (error.response && error.response.status === 401 && error.response.data && (error.response.data.detail === 'Signature has expired.' || error.response.data.detail === 'Error decoding signature.')) {
        if (error.response.status !== 409) {
          store.dispatch('autoLogout');
        }
        store.dispatch('sessionSync');
        store.dispatch('updateSnackbar', {
          color: 'info',
          show: true,
          text: 'Session expired, Please log-in again!'
        });
      } else {
        rtn = Promise.reject(error);
      }
      return rtn;
    });
  });
  
  
  router.afterEach((to, from) => {
    setTimeout(() => {
      store.state.appLoading = false;
    }, 1500);
  });
  
  export default router;
  