export default {
  user: {},
  snackbar: {
    show: false,
    color: '',
    mode: '',
    text: '',
  },
  activeTab: '',
  activeCompany: {},
};
